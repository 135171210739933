const projects = [
    {
        title: 'Reigning Phoenix Music',
        description: 'We built this WordPress site with extensive customizations to the theme and styling. We also maintain the CRM and execute digital marketing campaigns, as well as provide tech support.',
        image: require('./assets/rpm-logo.png'),
        siteLink: 'https://reigningphoenixmusic.com/'
    },
    {
        title: 'Deicide',
        description: 'We built this WordPress site with extensive customizations to the theme and styling. We also maintain the CRM and execute digital marketing campaigns, as well as provide tech support.',
        image: require('./assets/deicide-logo.png'),
        siteLink: 'https://deicideofficial.com/'
    },
    {
        title: 'LBRTE',
        description: 'We built this WordPress site with extensive customizations to the theme and styling.',
        image: require('./assets/lbrte-logo.png'),
        siteLink: 'https://staging3.lbrtecannabisco.com/'
    },
    {
        title: 'Finance App',
        description: 'We built this responsive full-stack application for displaying vital business KPIs and data, and it utilizes machine learning for predictive analytics. It\'s an easily modifiable and scalable platform designed to suit your business\'s branding and reporting needs.',
        image: require('./assets/finance-app.png'),
        siteLink: 'https://financeapp-ebon.vercel.app/'
    },
    {
        title: 'RidingEasy Records',
        description: 'We maintain this WordPress eCommerce site and marketing CRM and manage domain and hosting services. We also provide custom theme modifications and JavaScript for integrations between the site and CRM to improve digital marketing performance.',
        image: require('./assets/ridingeasy-logo.png'),
        siteLink: 'https://www.ridingeasyrecs.com/'
    },
    {
        title: 'Blue Dot Sessions - Browser Stem Editor',
        description: 'We developed save and load functionality and frontend design for the next generation of an existing in-browser digital audio worksation. This included modifications the existing Redux store and API.',
        image: require('./assets/blue-dot-sessions-logo.jpeg'),
        siteLink: 'https://www.sessions.blue/'
    },
    // {
    //     title: 'Matters @ Play',
    //     description: 'We provided custom theme modifications and extensive styling and design to the Matters @ Play WordPress site.',
    //     image: require('./assets/map-logo.png'),
    //     siteLink: 'https://mattersatplay.com/'
    // },
    {
        title: 'DePaul University - Immigration Games',
        description: 'We provided custom theme modifications and plugin integrations to the Immigration Games WordPress site.',
        image: require('./assets/depaul-university-logo.png'),
        siteLink: 'https://immigrationgames.com/'
    },
    {
        title: 'Three Corgis',
        description: 'We maintain this WordPress eCommerce site and marketing CRM and manage domain and hosting services. We also provide custom theme modifications and JavaScript for integrations between the site and CRM to improve digital marketing performance.',
        image: require('./assets/threecorgis-logo.jpeg'),
        siteLink: 'https://www.threecorgis.com/'
    },
    {
        title: 'Personal Website - Dr. Alexandra To',
        description: 'We converted Dr. To\'s site from an HTML page to a React single-page application and accommodated her existing HTML theme and styling. This site is deployed using GitHub Pages.',
        image: require('./assets/NEU-logo.png'),
        siteLink: 'https://www.alexandrato.com/'
    },
    // {
    //     title: 'Personal Website - Yesenia Cisneros',
    //     description: 'We redesigned and customized the theme of Yesenia\'s existing WordPress site.',
    //     image: require('./assets/YeseniaLogo_purple_small.png'),
    //     siteLink: 'https://www.yeseniac.com/'
    // },
]

export default projects