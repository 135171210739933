const projectCarouselImages = [
  {
    title: 'LBRTE Site',
    description: 'We built this site to enable our client to engage with investors and customers prior to their product rollout',
    image: require('./assets/lbrte-home.png'),
    siteLink: 'https://www.sessions.blue/'
  },
  {
    title: 'Blue Dot Sessions - In-Browser Digital Audio Work Station (DAW)',
    description: 'We designed and implemented this modal for saving and loading customized songs in the Blue Dot DAW',
    image: require('./assets/SaveModal.png'),
    siteLink: 'https://www.sessions.blue/'
  },
  {
    title: 'Blue Dot Sessions - In-Browser Digital Audio Work Station (DAW)',
    description: 'Customized song loaded into the Blue Dot DAW',
    image: require('./assets/CustomMixLoad.png'),
    siteLink: 'https://www.sessions.blue/'
  },
  {
    title: 'RidingEasy Records - Custom API Integrations',
    description: 'We write custom scripts for integrating the WooCommerce eCommerce plugin and Klaviyo CRM to enable additional functionality, like back in stock email notifications',
    image: require('./assets/RE_BIS.png'),
    siteLink: ''
  },
  {
    title: 'eCommerce Mock-Up',
    description: 'we built a feature-rich eCommerce site from the ground up, including user account creation, a fully-functional cart, PayPal integration, and an admin area',
    image: require('./assets/eCommerceMainPage.png'),
    siteLink: ''
  },
  {
    title: 'eCommerce Mock-Up',
    description: 'Custom eCommerce checkout page featuring PayPal integration',
    image: require('./assets/eCommerceCheckoutScreen.png'),
    siteLink: ''
  },
]

export default projectCarouselImages