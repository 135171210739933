import React from "react";

function ContactIcon({height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height={height ? height : 469.17}
      data-name="Layer 1"
      viewBox="0 0 912.67 469.17"
      aria-labelledby="title"
    >
      <title id='title' lang='en'>Person taking a phone call</title>
      <path
        fill="#f2f2f2"
        d="M663.748 290.03a2.807 2.807 0 01-2.034-4.866l.192-.765-.076-.184a7.54 7.54 0 00-13.907.052c-2.275 5.478-5.17 10.966-5.883 16.758a22.304 22.304 0 00.391 7.67 89.42 89.42 0 01-8.134-37.138 86.31 86.31 0 01.535-9.629q.444-3.93 1.23-7.806a90.455 90.455 0 0117.939-38.338 24.073 24.073 0 0010.012-10.387 18.363 18.363 0 001.67-5.018c-.488.064-1.838-7.359-1.47-7.814-.68-1.031-1.895-1.543-2.637-2.55-3.69-5.001-8.773-4.128-11.426 2.67-5.67 2.86-5.724 7.605-2.246 12.169 2.213 2.903 2.517 6.831 4.459 9.94-.2.255-.408.503-.607.759a91.039 91.039 0 00-9.503 15.054 37.846 37.846 0 00-2.259-17.58c-2.163-5.216-6.216-9.61-9.786-14.12-4.288-5.418-13.08-3.054-13.836 3.814l-.021.2q.795.448 1.557.952a3.808 3.808 0 01-1.535 6.93l-.078.012a37.89 37.89 0 00.999 5.665c-4.58 17.71 5.307 24.16 19.424 24.45.312.16.615.32.927.471a92.924 92.924 0 00-5.002 23.54 88.135 88.135 0 00.064 14.23l-.024-.167a23.29 23.29 0 00-7.95-13.448c-6.119-5.026-14.763-6.877-21.363-10.917a4.37 4.37 0 00-6.694 4.252l.027.177a25.578 25.578 0 012.868 1.382q.796.449 1.557.953a3.808 3.808 0 01-1.535 6.93l-.078.011-.16.025a37.923 37.923 0 006.976 10.922c2.863 15.46 15.162 16.927 28.318 12.425h.008a92.897 92.897 0 006.24 18.218h22.293c.08-.248.152-.504.224-.751a25.329 25.329 0 01-6.169-.368c1.654-2.03 3.308-4.075 4.962-6.104a1.386 1.386 0 00.104-.12c.839-1.039 1.686-2.07 2.525-3.108v-.002a37.102 37.102 0 00-1.087-9.45zM730.512 347.797H556.764a44.626 44.626 0 00-44.626 44.626v74.374h14v-41.04a44.626 44.626 0 0144.626-44.626h145.748a44.626 44.626 0 0144.626 44.626v41.04h14v-74.374a44.626 44.626 0 00-44.626-44.626z"
      ></path>
      <ellipse
        cx="644.638"
        cy="360.297"
        fill="#e6e6e6"
        rx="45.5"
        ry="6.5"
      ></ellipse>
      <path
        fill="#f2f2f2"
        d="M679.035 361.927H618.43c-14.961-14.338-25.658-28.156-17.094-39.626a12.432 12.432 0 0112.432-12.432h69.928a12.432 12.432 0 0112.431 12.432c3.261 13.07-2.564 26.28-17.093 39.626z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M478.095 147.73c-72.307-41.02-44.64-146.65-124.071-113-103.142-24.562-44.515 78.034-136.071 113a31.929 31.929 0 00-31.929 31.93 31.929 31.929 0 0033.05 31.908l11.95-.42V311.73h234V211.148l11.95.42a31.929 31.929 0 0033.05-31.909 31.929 31.929 0 00-31.929-31.928zM455.772 358.73H237.276a9.252 9.252 0 00-9.252 9.253v13.496a9.252 9.252 0 009.252 9.252h16.748v78h12v-78h156v78h12v-78h21.748a9.252 9.252 0 009.252-9.252v-13.496a9.252 9.252 0 00-9.252-9.252z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M500.772 336.73h-6.699a21.89 21.89 0 002.951-11v-128a22 22 0 00-44 0v105.795c-164.694-23.132-57.365-22.899-206 .736v-106.53a22 22 0 00-44 0v128a21.89 21.89 0 002.95 11h-13.698a9.252 9.252 0 00-9.252 9.252v13.496a9.252 9.252 0 009.252 9.252h308.496a9.252 9.252 0 009.252-9.252v-13.496a9.252 9.252 0 00-9.252-9.252z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M190.072 206.006a14.981 14.981 0 0016.238-13.152 14.373 14.373 0 00.047-2.375l91.61-45.325-22.255-26.594-85.958 58.613a14.94 14.94 0 00-12.749 13.044 14.297 14.297 0 0012.676 15.752q.196.02.391.037z"
      ></path>
      <path
        fill="#2f2e41"
        d="M391.378 123.752s8.897-17.653-18.882-24.058-79.065 15.164-81.674 17.627.78-.882-7.473-2.165c-3.963-.617-11.873-7.321-13.498-1.68s-1.175 12.418-5.928 6.933 13.977 46.894 13.977 46.894 2.417-.389 10.985-4.931c4.813-2.552 4.863-13.146 8.083-10.182s31.845 2.46 33.454 2.07 8.822-13.672 8.822-13.672z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M242.511 452.241L227.26 449.061 232.263 388.722 254.773 393.414 242.511 452.241z"
      ></path>
      <path
        fill="#2f2e41"
        d="M239.732 468.263l-46.9-9.775.124-.593a18.648 18.648 0 0122.059-14.45l9.922-4.714 14.63 9.83 4.094.854z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M505.105 382.304L520.285 378.798 513.985 318.58 491.582 323.754 505.105 382.304z"
      ></path>
      <path
        fill="#2f2e41"
        d="M508.228 398.263l46.679-10.78-.136-.59a18.648 18.648 0 00-22.365-13.974h-.001l-10.02-4.5-14.415 10.144-4.075.94zM326.024 243.73l-2.618 29.617s-85.018-27.304-86.052 36.922c-.33 20.462-9.33 99.462-9.33 99.462l26 4s14-21 14-28 14-58 14-58l72.967-7.177c77.033-4.823 101.033-6.823 118.033-61.823 0 0 10.364 73.586 16.182 79.293s10.125.033 2.971 7.37-12.153 4.337-7.153 7.337 35-2 35-2c6.7.751-1.892-106.474-24.345-138.119-20.036-28.237-89.165 60.497-89.165 60.497zM416.108 117.749s25.987-2.95 31.523 9.504 19.484 38.387 19.484 38.387-7.153 3.047.152-4.87c3.274-3.548-2.768-1.384 1.384-2.767s5.535 0 5.535 0l13.838 23.524s-1.113 5.36-8.303 5.535 4.152-6.919-5.535 2.768c-5.001 5.001-23.162 11.9-23.162 11.9l-46.422-42.661z"
      ></path>
      <path
        fill="#47f4ad"
        d="M334.16 105.112l26.265-6.806 12.26-16.91 29.117 3.58L408 108.04l20.729 12.032s-11.457 121.181-8.732 127.835-3.888 12.22-12.233 20.798 6.934-.993 8.87 8.345 16.053 19.221 0 19.123-104.352 4.36-101.584-6.711-5.842-14.1-1.67-21.041 6.983-3.062 5.578-13.304-.02-17.161-.02-22.697 4.636-55.834 4.636-55.834z"
      ></path>
      <path
        fill="#2f2e41"
        d="M428.52 296.382c5.01 8.94-23.205 11.568-53.773 10.365a20.273 20.273 0 00-2.893-6.338c-3.376-4.899.706-38.04-4.22-41.39 2.228 5.397-6.78 41.265-8.663 46.787-27.648-2.215-52.847-7.238-52.542-13.216.083-1.743 5.853-34.954 8.648-37.473 2.782-2.518-.027-50.065 0-60.264.014-10.212 15.194-85.283 15.194-88.05s35.98-16.606 35.98-16.606l15.22 87.179L404.61 95.4a48.923 48.923 0 007.79 8.344c4.636 3.86 23.04 19.664 23.04 19.664l-15.76 116.626s7.458 17.602 7.07 24.59a49.014 49.014 0 01-3.224 16.204c-2.241 6.573-2.006 3.044 4.996 15.554z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M453.278 55.399a14.981 14.981 0 011.246 20.858 14.372 14.372 0 01-1.721 1.637l28.346 98.2-34.645 1.514-14.723-102.993a14.94 14.94 0 011.027-18.21 14.297 14.297 0 0120.18-1.27q.147.13.29.264z"
      ></path>
      <path
        fill="#2f2e41"
        d="M441.048 169.229c1.106-.881 35.584-12.075 35.584-12.075s14.643 21.785 5.794 28.835-28.179 8.887-28.179 8.887-8.121-4.57-7.93-9.955-5.27-15.692-5.27-15.692z"
      ></path>
      <path
        fill="#3f3d56"
        d="M419.714 68.289l3.748-27.6c.52-3.828 3.135-6.34 5.828-5.602l6.7 1.836c2.694.738 4.463 4.453 3.943 8.28l-3.748 27.6c-.52 3.827-3.134 6.34-5.828 5.602l-6.7-1.836c-2.693-.738-4.462-4.453-3.943-8.28z"
      ></path>
      <path
        fill="#47f4ad"
        d="M421.572 66.686l3.82-25.247c.529-3.501 2.435-5.723 4.248-4.954l4.509 1.915c1.813.77 2.857 4.244 2.327 7.745l-3.82 25.248c-.529 3.5-2.435 5.723-4.248 4.953L423.9 74.43c-1.813-.77-2.857-4.244-2.327-7.745z"
      ></path>
      <path
        fill="#2f2e41"
        d="M350.31 40.462c2.604 21.017 17.167 38.346 38.345 38.346A38.346 38.346 0 00427 40.462c0-21.178-17.26-36.388-38.346-38.346C366.28.04 346.965 13.48 350.309 40.462z"
      ></path>
      <circle cx="392.894" cy="47.081" r="34.003" fill="#ffb6b6"></circle>
      <path
        fill="#2f2e41"
        d="M357.683 50.786c1.077 7.389 7.374 17.698 5.9 17.698s-11.961-30.46-1.475-32.447c7.606-1.44 9.306-.177 16.415-3.26l-1.003.945c4.705 3.421 11.253 1.312 16.621-.915 5.383-2.212 11.917-4.32 16.636-.914 2.965 2.139 4.218 5.855 6.062 9.011 1.843 3.171 5.25 6.165 8.76 5.192 2.817-.782 4.44-3.938 4.425-6.858s-3.817-5.698-2.537-8.318c3.795-7.765.382-13.057-7.89-16.71q-4.513-.686-9.026-1.401a25.601 25.601 0 015.663-5.693c-1.12-2.286-2.404-4.587-4.572-5.855-2.404-1.43-5.383-1.328-8.17-1.18l-18.657.973c-4.601.236-9.365.516-13.48 2.625-5.132 2.64-8.495 7.788-10.943 13.023-5.487 11.77-4.617 21.238-2.729 34.084z"
      ></path>
      <path
        fill="#ccc"
        d="M0 467.98a1.186 1.186 0 001.19 1.19h910.29a1.19 1.19 0 100-2.38H1.19A1.187 1.187 0 000 467.98z"
      ></path>
    </svg>
  );
}

export default ContactIcon;
